import runners from './runners.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={runners} alt="logo" className="logo"/>
      </header>
        <h1>Coming soon...</h1>
        <a href="https://www.facebook.com/RegionofRunners/">
        <img src="fb.png" alt="facebook logo" className="socials"/></a>
        <a href="https://www.instagram.com/regionofrunners/?hl=en">
        <img src="insta.png" alt="facebook logo" className="socials"/></a>
    </div>
  );
}

export default App;
